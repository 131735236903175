<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="text-gray-200"
  >
    <path d="M19.2314 14.748V17.02H12.1914V14.748H19.2314Z" fill="currentColor" />
    <path d="M31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16ZM3.6 16C3.6 22.8483 9.15167 28.4 16 28.4C22.8483 28.4 28.4 22.8483 28.4 16C28.4 9.15167 22.8483 3.6 16 3.6C9.15167 3.6 3.6 9.15167 3.6 16Z" fill="currentColor" />
    <path d="M16 0.5C19.2733 0.5 22.4625 1.53625 25.1107 3.46024C27.7588 5.38422 29.7299 8.09716 30.7414 11.2102L27.7931 12.1682C26.9839 9.67773 25.407 7.50738 23.2885 5.96819C21.17 4.429 18.6186 3.6 16 3.6V0.5Z" fill="currentColor" />
  </svg>
</template>
